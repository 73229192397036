.story-card {
  // height: 100%;
  // min-height: 0px;
  // width: 90vw;
  // min-width: 90vw;
  color: white;
  border-bottom: 1px solid grey;
  padding: 0 15px;
  // overflow-y: scroll;
  a {
    text-decoration: none;
    color: white;
  }
  a:hover {
    text-decoration: none;
    color: white;
  }
  .count {
    padding: 10px 0;
    color: grey;
  }
  .image {
    height: 90vw;
    background-position: center;
    background-size: cover;
  }
  .title {
    font-weight: normal;
    font-size: 1.2rem;
    text-decoration: underline;
  }

  .description {
    font-size: 0.75rem;
  }
}

.story-card.disabled {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.6;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .story-card {
    width: 430px;
    min-width: 430px;
    height: 100%;
    min-height: 0px;
    border-right: 1px solid grey;
    border-bottom: none;
    overflow-y: scroll;

    .image {
      height: 300px;
    }

    .title {
      font-size: 2rem;
      text-decoration: none;
    }

    .description {
      font-size: 1rem;
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
