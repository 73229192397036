.openMobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 56px;
  height: 56px;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;

  .button {
    background-color: var(--yellow-post);
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.side-menu,
.side-menu-expanded {
  position: fixed;
  height: 100%;
  z-index: 1000;
  width: 56px;
  font-size: 14px;
  top: 0;
  left: 0px;
  background-color: var(--yellow-post);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Vertical label */
  .side-menu-vertical-label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    width: 56px;
  }
  .side-menu-vertical-label {
    transform: rotate(180deg);
    font-family: "Archivo Black", sans-serif;
  }

  .side-menu-desc-container {
    max-width: 285px;
    padding: 15px;
  }

  .side-menu-home-link {
    color: white;
    text-decoration: none;
  }

  .side-menu-home-link:hover {
    color: white;
    text-decoration: none;
  }

  /* Link */
  .side-menu-link {
    display: flex;
    height: 56px;
    display: flex;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #d8d8d8;
  }
  .side-menu-link:hover,
  .side-menu-link:active {
    text-decoration: none;
    color: black;
  }
  .side-menu-link.active {
    background-color: #f4ae0c;
  }
  .side-menu-link > .link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
  }

  .side-menu-link > .link-label {
    flex: 1;
    align-items: center;
    display: flex;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.375rem;
  }
  /* Lang item */
  .side-menu-lang {
    display: flex;
    height: 40px;
    width: 56px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .side-menu-lang.current-lang {
    font-weight: bold;
  }

  .side-menu-bottom .lang {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    height: 100%;
    padding: 5px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
  }

  .side-menu-bottom .lang.info {
    height: 56px;
    border-bottom: 1px solid #d8d8d8;
  }

  .side-menu-bottom .lang.info a:hover {
    text-decoration: none;
  }

  .side-menu-bottom .lang.current-lang {
    text-decoration: underline;
  }

  .side-menu-logo {
    height: 80px;
  }
}

.side-menu-expanded {
  z-index: 999;
  width: auto;
  max-width: 100%;
  background-color: white;
  transform: translateX(calc(-100% - 56px));
  transition: transform 0.3s linear;
  left: 56px;

  .link-label {
    padding: 0 15px;
  }

  .side-menu-link:hover {
    transition: background-color 0.3s linear;
  }

  .side-menu-link:hover {
    background-color: var(--yellow-post);
  }

  .side-menu-link.active:hover {
    background-color: #f4ae0c;
  }

  .side-menu-logo img {
    filter: grayscale(1);
  }

  .side-menu-logo img:hover {
    filter: grayscale(0);
  }
}

.side-menu-expanded.open {
  transform: translateX(0%);
}

.side-menu {
  transform: translateX(-100%);
  transition: transform 0.3s linear;
}

.side-menu.open {
  transform: translateX(0%);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .side-menu {
    transform: translateX(0%);
  }

  .side-menu-expanded {
    max-width: 300px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
