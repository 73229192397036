.Home {
  .background-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .cols-grid {
    opacity: 0.2;
  }

  .abstract {
    font-size: 0.85rem;
  }

  h1 {
    font-size: 2rem;
  }

  .section-container {
    border-top: 5px white solid;
    padding: 22px 0 23px 22px;
    transition: border-top 0.3s linear;
    display: flex;
    align-items: center;
  }

  .section-container:hover {
    border-top: 5px var(--yellow-post) solid;
  }

  .section-text {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1rem;
    transform: translateX(-24px);
    transition: transform 0.3s linear;
  }

  .moving-container {
    overflow: hidden;
  }
  .section-container:hover .moving-icon {
    transform: translateX(0);
  }

  .section-container:hover .section-text {
    transform: translateX(0);
  }

  .moving-icon {
    transform: translateX(-24px);
    width: 24px;
    transition: transform 0.3s linear;
  }

  .read-more {
    font-family: "IBM Plex Sans", sans-serif;
    border-bottom: 1px solid var(--yellow-post);
  }

  a:hover {
    text-decoration: none;
  }

  .row-bottom-menu {
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding-left: 56px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .Home {
    h1 {
      font-size: 2.5rem;
    }

    .row-bottom-menu {
      overflow-x: hidden;
      flex-wrap: wrap;
      padding-left: 0;
    }

    .section-container {
      min-height: 136px;
      padding: 15px 0px 5px 15px;
    }

    .section-text {
      font-size: 1.4rem;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
