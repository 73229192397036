.Map {
  margin-left: 0px;
  .title {
    background-color: black;
    border-color: black !important;
    z-index: 889;
    position: relative;
    h2 {
      font-size: 1.5rem;
      padding-left: 56px;
    }
  }
  .top-bar {
    height: 56px;
    font-size: 0.85rem;
  }

  .top-filters {
    height: auto;
    font-size: 0.85rem;
  }

  .filters-container {
    position: absolute;
    top: 56px;
    z-index: 888;
    width: 100%;
    transform: translateY(-100%);
    background-color: black;
    border-color: black !important;
    transition: transform 0.3s ease-in-out;
  }

  .filters-container.open {
    transform: translateY(0%);
  }

  .navigation-container,
  .timeline-container {
    background-color: var(--dark-post);
  }

  .map-filters {
    font-family: "IBM Plex Sans", sans-serif;
    cursor: pointer;
    border-bottom: 1px solid white;
    width: 100%;
  }

  .map-opacity {
    font-family: "IBM Plex Sans", sans-serif;
  }

  .map-opacity input {
    width: 100px;
  }

  .map-filters:not(.active) {
    opacity: 0.3;
  }

  .timeline-container {
    border-color: black !important;
  }

  .place-marker {
    width: 30px;
    height: 30px;
    border-radius: 999px;
  }

  .place-selected {
    border: 2px solid var(--yellow-post);
  }

  .place-clickable {
    cursor: pointer;
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .Map {
    margin-left: 56px;

    .map-filters {
      border-bottom: none;
      width: auto;
    }

    .filters-container {
      position: relative;
      top: 0px;
      transform: translateY(0%);
      background-color: var(--dark-post);
    }

    .top-filters {
      height: 56px;
    }
    .title {
      background-color: var(--dark-post);
      h2 {
        font-size: 2rem;
        padding-left: 0px;
      }
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .Map {
    .top-bar {
      font-size: 1rem;
    }
  }
}
