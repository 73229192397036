.CollectionPage {
  margin-left: 0px;

  .no-results {
    height: calc(100% - 56px);
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .CollectionPage {
    margin-left: 56px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
