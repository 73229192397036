.doc-detail {
  background-color: black;
  .doc-detail-bar {
    background: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 56px;
  }

  .close-icon {
    cursor: pointer;
    transform: rotate(180deg);
    height: 56px;
  }

  .doc-detail-data {
    width: 100%;
    flex: 1;
  }

  .doc-detail-info-container {
    height: 50%;
    flex: 1 1;
    min-height: 1px;
  }

  .external-viewer,
  .external-viewer:hover {
    color: black;
    text-decoration: underline;
  }

  .doc-detail-info {
    background-color: white;
    overflow-y: auto;
    padding-top: 1rem;
  }

  .doc-detail-info > :first-child {
    border-bottom: none;
  }

  .detail-title {
    text-transform: uppercase;
    color: var(--yellow-post);
  }

  .close-container {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
  }

  .square {
    position: relative;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    box-sizing: border-box;
    cursor: pointer;
  }

  .square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .square-content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 5px;
    object-fit: cover;
  }

  .doc-media-container {
    height: 50%;
  }

  .360viewer-iframe {
    overflow: hidden;
  }

  .doc-media-video {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .doc-detail {
    .doc-detail-info-container {
      height: 100%;
    }
    .doc-detail-info > :first-child {
      border-bottom: 1px solid #dee2e6;
    }
    .square {
      flex: 0 0 45%;
      max-width: 45%;
    }
    .square-content {
      filter: grayscale(1);
      transition: filter 0.25s linear;
    }
    .square-content:hover {
      filter: grayscale(0);
    }
    .doc-media-container {
      min-height: 100%;
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .doc-detail {
    .square {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}
