.HomeMenu {
  z-index: 999;
  width: 56px;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80px;
  background-color: var(--yellow-post);

  .lang,
  .info {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    height: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
  }

  .info {
    height: 56px;
    border-bottom: 1px solid #d8d8d8;
  }

  .lang.current-lang {
    text-decoration: underline;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .HomeMenu {
    width: 56px;
    height: 136px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
