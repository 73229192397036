.video-story {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .video-story-top {
    height: 56px;
    display: flex;
    flex-direction: row;
    background-color: var(--dark-post);

    .control-button {
      background-color: #ffffff;
      height: 56px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .close-icon {
      transform: rotate(180deg);
    }

    .track-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      .video-meta {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
      }

      .audio {
        cursor: pointer;
      }

      .track {
        height: 10px;
        background-color: #7b7b7b;
        position: relative;
        cursor: pointer;

        .side-doc {
          position: absolute;
          height: 100%;
          background: #f8c91c;
          border: solid #000 1px;
          width: 10px;
        }

        .progress {
          background-color: var(--yellow-post);
          opacity: 0.75;
          position: absolute;
          height: 100%;
          border-radius: 0;
          pointer-events: none;
        }
      }
    }
  }

  .video-story-video {
    flex: 1;
    display: flex;
    overflow: auto;
    .video-container {
      height: 100%;
      flex: 1;
      overflow: hidden;
      video {
        object-fit: cover;
      }
    }
    .side-document {
      background: black;
      position: relative;
      .handle-circle-container {
        cursor: col-resize;
        top: calc(50% - 25px);
        left: calc(-25px);
        width: 50px;
        height: 50px;
        position: absolute;
      }
      .display-doc {
        background: black;
        height: 100%;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .truncate-parent {
            min-width: 0;
          }
          h6 {
            font-family: "IBM Plex Sans Condensed", sans-serif;
            font-size: 1.25rem;
            font-weight: 400;
            margin-bottom: 0;
          }
          .doc-open {
            text-transform: uppercase;
            color: white;
            text-decoration: none;
            border-bottom: 1px solid var(--yellow-post);
          }
          .doc-open:hover {
            text-decoration: none;
          }
        }
        .doc-image {
          flex: 1;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .doc-video {
          flex: 1;
          height: 100%;
          overflow: hidden;
        }
        .doc-video video {
          object-fit: contain;
        }
      }
    }
  }

  .video-story-bottom {
    height: 200px;
    display: flex;
    padding-left: 56px;

    .speaker h1 {
      font-family: "IBM Plex Sans Condensed", sans-serif;
      font-size: 2.25rem;
    }
  }
}
