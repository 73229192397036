.CookieBanner {

  position: fixed;
  align-items: center;
  bottom: 0;
  z-index: 9999999;
  background-color: rgba(248, 201, 28, 0.9);
  border-top: 1px solid gray;
  padding: 15px;

  .message {
    font-weight: 400;
  }

  button {
    background-color: white;
    border-radius: 999px;
    margin: 0 10px;
  }
}
