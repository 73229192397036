.time-series {
  overflow-y: auto;
  background-color: var(--dark-post);

  .time-serie {
    border-right: 1px solid #fff;
  }

  .time-serie h6 {
    text-transform: uppercase;
    font-family: "IBM Plex Mono", monospace;
    font-weight: 400;
    font-size: 0.75rem;
  }

  .time-serie svg {
    overflow: visible;
  }

  .time-serie p {
    font-family: "IBM Plex Sans Condensed", sans-serif;
  }

  .time-serie .time-serie-chart-container {
    height: 50px;
  }
  .time-serie .time-serie-chart-container h4 {
    font-weight: normal;
    font-size: 36px;
    flex: 0.33;
  }
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
  .time-series-container {
    height: 100%;
  }
  .time-series {
    .time-serie {
      border-right: none;
    }
  }
}

@media (min-width: 1200px) {
}
