.pic-content {
  background-size: cover;
}

.pic-content.selected {
  background-size: contain;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .pic-content,
  .pic-content.selected {
    background-size: cover;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
