@import url("https://fonts.googleapis.com/css?family=Archivo+Black|IBM+Plex+Mono:400,400i|IBM+Plex+Sans+Condensed:400,400i,600,600i|IBM+Plex+Sans:400,400i,600,600i");
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";

/*

    font-family: 'Archivo Black', sans-serif;

    font-family: 'IBM Plex Sans', sans-serif;

    font-family: 'IBM Plex Mono', monospace;

    font-family: 'IBM Plex Sans Condensed', sans-serif;

*/

:root {
  --yellow-post: #f8c91c;
  --dark-post: #25272b;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #000;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#root {
  height: 100%;
}

.with-sidemenu {
  padding-left: 56px;
}

button:focus {
  outline: 0px;
}

h1,
h2,
h3 {
  font-family: "Archivo Black", sans-serif;
  font-weight: normal;
}

h4,
h5,
h6 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
}

/* general utilities */
.h-100vh {
  height: 100vh;
}

.h-100px {
  height: 100px;
}

.overflow-auto {
  overflow: auto;
}

.flex-1 {
  flex: 1;
}

.home-flex-1 {
  flex: auto;
}

.flex-2 {
  flex: 2;
}

.cover {
  background-size: cover !important;
}

.period-cover {
  background-position: center center;
}

.max-h-100 {
  max-height: 100% !important;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  stroke: white;
  stroke-width: 2px;
}

.m-l-auto {
  margin-left: auto;
}

#pics-container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /*background-image: linear-gradient(to top, #131313, rgba(19, 19, 19, 0));*/
}

/*fonts & text utilities */
.font-9 {
  font-size: 9px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.lead-24 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.33;
}

.lead-48 {
  font-size: 48px;
  font-weight: 300;
}

.text-grey {
  color: #9b9b9b;
}

/* custom background styles */
.bg-black {
  background-color: #000;
  color: #fff;
}

.bg-opaque-black {
  background-color: #1d1d1d;
  color: #fff;
}

.bg-darkgrey {
  background-color: #383838;
}

.bg-overlay {
  background-color: rgba(49, 48, 48, 0.7);
}

.grayscale {
  filter: grayscale(100%);
}

/* custom components styles */

.page-modal {
  .modal-title {
    width: 100%;
    text-align: center;
    opacity: 0.5;
  }
  .modal-content {
    border-radius: 0px;
  }
  .modal-footer {
    .btn {
      border-radius: 0px;
    }
  }
}

.timeline-legend-intro {
  flex-grow: 1;
  overflow: auto;
}

.timeline-legend {
  flex-shrink: 0;
  flex-grow: 0;
}

.timeline-legend-close {
  position: absolute;
  z-index: 999;
  top: 15px;
  right: 15px;
}

.fill-grey {
  fill: #828282;
}

.fill-white {
  fill: #fff;
}

// .legend-detail-image {
//   min-height: 350px;
// }
//
// .legend-close-btn {
//   right: -30px;
// }
//
// .legend-close {
//   width: 53px;
//   height: 53px;
// }

.map-with-over-place {
  opacity: 0.2;
}

/*.mapboxgl-popup {
  z-index: 99999;
}

.mapboxgl-popup-content {
  pointer-events: none;
}*/

.mapboxgl-popup-tip {
  opacity: 0;
}
.mapboxgl-ctrl-attrib {
  pointer-events: none;
  display: none;
}
.mapboxgl-popup-content {
  background: transparent;
  border: 0;
  padding: 0;
  pointer-events: none;
}

.map-year {
  position: absolute;
  top: 18px;
  left: 18px;
  color: white;
  font-size: 3rem;
}
.map-tooltip {
  width: 100%;
  height: 300px;
}

.event-modal-control:hover {
  color: grey;
}

.opening-timeline-bgline {
  stroke: #b3b3b3;
  stroke-width: 2;
}
.opening-timeline-open {
  fill: #13d436;
  stroke: #13d436;
  stroke-width: 2;
}
.opening-timeline-closed {
  fill: #ed6347;
  stroke: #ed6347;
  stroke-width: 2;
}
.opening-timeline-text {
  font-size: 10px;
  fill: #4a4a4a;
  line-height: 2.2;
}

.period-title {
  color: #fff;
  text-shadow: 1px 1px #222;
}
.period-description {
  color: #fff;
}
.period-years {
  color: #fff;
}
.period-label {
  margin-bottom: 10px;
  color: #fff;
}

.topbar-button {
  cursor: pointer;
}

.menu-link {
  color: white;
}

.menu-link:hover {
  color: white;
}

.mobile-alert {
  position: fixed;
  top: 0;
  text-align: center;
  font-size: 15pt;
  left: 0;
  right: 0;
  /* color: #fffafa; */
  background-color: #eeee00;
  z-index: 99999;
}

.text-underlined {
  text-decoration: underline;
}

.menu-logo-img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: 90%;
}

.menu-logo-cont {
  flex: 0 1 33%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 5px;
}

/* switch toggle start from https://codepen.io/claviska/pen/KyWmjY */

$switch-height: calc(#{$input-height} * 0.8) !default;
$switch-height-sm: calc(#{$input-height-sm} * 0.8) !default;
$switch-height-lg: calc(#{$input-height-lg} * 0.8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: black !default;
$switch-checked-bg: black !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
$switch-disabled-color: var(--gray-light) !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width
  rgba(map-get($theme-colors, "primary"), 0.25);
$switch-transition: 0.2s all !default;

.switch {
  font-size: $font-size-base;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(#{$switch-height} * 2) + 0.5rem);
      margin-bottom: 0;
    }

    + label::before,
    + label::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{$switch-height} * 2);
      bottom: 0;
      display: block;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
    }

    + label::after {
      top: $switch-thumb-padding;
      left: $switch-thumb-padding;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label::after {
      margin-left: $switch-height;
    }

    &:focus + label::before {
      outline: none;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      background-color: $switch-disabled-bg;
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + 0.5rem);
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  // Large variation
  &.switch-lg {
    font-size: $font-size-lg;

    input {
      + label {
        min-width: calc(#{$switch-height-lg} * 2);
        height: $switch-height-lg;
        line-height: $switch-height-lg;
        text-indent: calc(calc(#{$switch-height-lg} * 2) + 0.5rem);
      }

      + label::before {
        width: calc(#{$switch-height-lg} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-lg;
      }
    }
  }

  + .switch {
    margin-left: 1rem;
  }
}

/* switch toggle end */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .home-info-container {
    overflow-y: initial;
  }
  .home-flex-1 {
    flex: 1;
  }
  .home-h-100 {
    height: 100% !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
