.IeWarning {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1010;
  background-color: rgba(248, 201, 28, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;

  .message {
    max-width: 100%;
    width: 600px;
  }
}
