.pdf-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .pdf-container {
    display: flex;
    justify-content: center;
    flex: 1;
  }
  .pdf-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    .page-input {
      width: 50px;
      background: transparent;
      border: 1px solid #343a40;
      color: white;
      outline: none;
      height: 38px;
      padding-left: 6px;
      border-radius: 0.25rem;
    }
  }
}
