.CollectionFilters {
  .top-bar {
    height: 56px;
    background-color: black;
    border-color: #000 !important;
    z-index: 2;
    position: relative;
  }

  small {
    font-size: 75%;
  }

  .title {
    font-size: 1.5rem;
  }
}

.collection-filters.open {
  transform: translateY(0);
}

.collection-filters {
  position: fixed;
  z-index: 1;
  left: 56px;
  top: 56px;
  right: 0;
  left: 0;
  bottom: 0;
  transform: translateY(-100%);
  background-color: black;
  overflow-y: scroll;
  transition: transform 0.25s linear;

  .filter-sub-title {
    color: var(--yellow-post);
    text-transform: uppercase;
    font-weight: 400;
  }

  .categories-radios {
  }

  .categories-radios .custom-control-label,
  .filter-brush-bottom .custom-control-label {
    color: white;
    font-family: "IBM Plex Sans", sans-serif;
    text-transform: capitalize;
  }

  .categories-radios .custom-checkbox {
    flex-basis: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .categories-radios
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before,
  .categories-radios
    .custom-control-input:checked
    ~ .custom-control-label::before,
  .filter-brush-bottom
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before,
  .filter-brush-bottom
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: white;
  }

  .categories-radios .custom-control-label::before,
  .filter-brush-bottom .custom-control-label::before {
    background-color: var(--dark-post);
    padding: 1px;
    border: 1px solid white;
  }

  .categories-radios
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after,
  .filter-brush-bottom
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2325272b' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }

  .filter-brush-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    color: white;
    flex-direction: column;
  }
}

// .collection-filters-categories {
//   display: flex;
// }
.collection-brush {
  fill-opacity: 0.25;
  fill: grey;
  stroke: #fff;
}

.collection-time-brush {
  height: 10vh;
  svg {
    margin: 0;
    padding: 0;
  }
}
.collection-ticks {
  width: 100%;
  height: 20px;
}
.collection-bars {
  /* border-bottom: 1px solid grey; */
  /* margin-left: 30px; */
  width: 100%;
  height: 30px;
}

.collection-list {
  height: calc(100% - 56px);
  padding-top: 15px;
  .collection-list-wrapper {
    height: 100%;
  }
}
.collection-row {
  // height: 430px;
  padding-bottom: 0px;
  .collection-col:last-child {
    border-right: 1px solid grey;
  }
}
.collection-col {
  border-right: 1px solid grey;
  margin-bottom: 10px;
}
.collection-col.order-1 {
  border-right: none;
}

.collection-col a {
  text-decoration: none;
}

.collection-col a:hover .item-label {
  text-decoration: underline;
}

.collection-col.col-placeholder {
  border: 0;
}

/* Collection item */
.collection-item {
  padding: 8px 5px;
  height: 260px;

  .item-image-box {
    overflow: auto;
    flex-basis: 0;
  }

  .item-image-box > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: 50% 100%;
  }

  .item-caption {
    margin-bottom: 5px;
  }

  .item-label {
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-size: 0.875rem;
  }
  .item-info {
    color: #9b9b9b;
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  .item-icon .material-icons {
    font-size: 1.2rem !important;
    line-height: normal;
  }

  .image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: grey;
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .collection-row {
    padding-bottom: 30px;
    .collection-col:last-child {
      border-right: none;
    }
  }

  .collection-item {
    height: unset;
    padding: 0 20px;

    .item-caption {
      margin-bottom: 40px;
    }

    .item-info {
      margin-bottom: 10px;
      font-size: 0.875rem;
    }

    .item-label {
      font-size: 1.25rem;
    }
  }
  .collection-col {
    margin-bottom: 0px;
  }

  .collection-col.order-1 {
    border-right: 1px solid gray;
  }

  .CollectionFilters {
    .top-bar {
      background-color: var(--dark-post);
    }
    .title {
      font-size: 2rem;
    }
  }

  .collection-filters {
    left: 56px;
    bottom: unset;
    background-color: var(--dark-post);

    .categories-radios .custom-checkbox {
      flex-basis: 50%;
      padding-top: 0px;
      padding-bottom: 0px;
      border: none;
    }

    .filter-brush-bottom {
      flex-direction: row;
    }
  }
}

@media (min-width: 992px) {
  .collection-time-brush {
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .CollectionFilters {
    small {
      font-size: 80%;
    }
  }
}
