.react-autosuggest__container {
  width: 100%;
  position: relative;
  height: 40px;
}

.react-autosuggest__input {
  width: 100%;
}

/* .react-autosuggest__input::placeholder{
  weight: 500;
  font-size: 14px;
} */

.react-autosuggest__input--focused {
  border: 1px solid #ced4da !important;
  outline: none;
  box-shadow: none !important;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 37px;
  width: 100%;
  border: 1px solid #ced4da;
  color: black;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-radius: 0px;
  z-index: 1023;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #212529 !important;
  color: #fff !important;
}
