.TimelinePeriodsMobile {
  position: relative;
  .period {
    position: absolute;
    bottom: 0px;
    padding: 5px 10px;
    background-color: white;
    color: black;
    width: 100%;
  }
  .description {
    margin-bottom: 0;
    padding: 10px 0px;
    font-size: 0.75rem;
  }
}
